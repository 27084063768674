<template>
  <Swiper
    :modules="[SwiperNavigation, SwiperAutoplay]"
    :slides-per-view="1.3"
    :centered-slides="false"
    :center-insufficient-slides="false"
    :loop="true"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: true,
    }"
    :speed="5000"
    :slides-offset-before="25"
    :space-between="24"
    :breakpoints="{
      '768': {
        slidesPerView: 1,
        spaceBetween: 72, // revert this to 20
        loop: true,
        slidesOffsetBefore: 0,
        centerInsufficientSlides: false,
        centeredSlides: false,
      },
    }"
    :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }"
    class="h-full"
  >
    <SwiperSlide v-for="(item, idx) in items" v-show="isLoaded" :key="idx">
      <div class="flex flex-row gap-x-15 relative">
        <picture>
          <AppSource
            :srcset="`${item.image} 480w, ${item.image} 800w, ${item.image} 1200w`"
            height="620"
            width="620"
            format="avif"
            fit="cover"
            :lazy="false"
          />
          <AppSource
            :srcset="`${item.image} 480w, ${item.image} 800w, ${item.image} 1200w`"
            height="620"
            width="620"
            format="webp"
            fit="cover"
            :lazy="false"
          />
          <AppSource
            :srcset="`${item.image} 480w, ${item.image} 800w, ${item.image} 1200w`"
            height="620"
            width="620"
            format="jpeg"
            fit="cover"
            :lazy="false"
          />
          <AppImage
            :src="item.image"
            :alt="item.title ?? ''"
            class="aspect-square rounded-5xs md:rounded-6xs object-cover md:w-[620px] h-[330px] md:h-[620px]"
            height="620"
            width="620"
            loading="lazy"
          />
        </picture>

        <div class="absolute [ bottom-7 left-7 right-7 ] md:static">
          <button
            v-if="currentIndex + 1 < items.length"
            aria-label="next-slide"
            class="hidden md:block"
            @click="nextSlide"
          >
            <svg-icon class="mt-17 w-60 h-30 text-primary-700" name="next-feature" />
          </button>

          <h3 class="md:max-h-[128px] overflow-hidden md:mt-17 text-white font-bold md:font-bold text-lg lg:text-2.6xl">
            <a href="href" target="_blank" class="text-left block font-display OfferCard__title">
              {{ item.title }}
            </a>
          </h3>

          <p v-if="item.description" class="text-base text-left text-white lg:text-secondary-700 lg:mt-5 max-w-100">
            {{ item.description }}
          </p>
        </div>
        <ul
          v-show="isLoaded"
          class="static md:absolute top-0 right-0 z-[1] [ text-primary-800 ] [ transform translate-x-8 ] [ hidden md:flex flex-row gap-x-5 items-center ]"
        >
          <li v-for="(content, index) in items.slice(idx + 1, idx + 3)" :key="content?.id ?? index" class="h-full">
            <img
              v-if="content?.image"
              :src="content?.image"
              alt=""
              width="216"
              height="216"
              class="h-full aspect-square rounded-3xl"
            />
          </li>
        </ul>
      </div>
    </SwiperSlide>
    <SwiperControls
      :current-index="currentIndex"
      @change="($event: number) => (currentIndex = $event)"
      @is-loaded="isSwiperLoaded"
    />

    <ul
      v-show="isLoaded"
      class="static md:absolute top-[640px] right-10 z-[1] [ text-primary-800 ] [ hidden md:flex flex-row gap-x-5 items-center ]"
    >
      <li
        v-for="(item, index) in items"
        :key="item?.id ?? index"
        :class="{
          'text-xl active-bullet': currentIndex === index,
          'text-base': currentIndex !== index,
        }"
      >
        <button aria-label="slide-to" @click="currentIndex = index">
          {{ `0${index + 1}` }}
        </button>
      </li>
    </ul>
  </Swiper>
</template>
<script lang="ts" setup>
defineProps<{
  items: {
    id: number;
    image: string;
    title: string;
    description: string;
  }[];
}>();
const currentIndex = ref(0);
const isLoaded = ref(false);

function nextSlide() {
  currentIndex.value += 1;
}

function isSwiperLoaded() {
  isLoaded.value = true;
}
</script>

<style lang="postcss" scoped>
.swiper,
swiper-container {
  --spread-value: 220px;
  height: calc(330px + (2 * var(--spread-value)));
  padding-bottom: calc(var(--spread-value));
  margin-bottom: calc(-1 * var(--spread-value));
  padding-top: calc(var(--spread-value));
  margin-top: calc(-1 * var(--spread-value));

  @screen lg {
    height: calc(635px + (2 * var(--spread-value)));
    padding-left: calc(var(--spread-value));
    margin-left: calc(-1 * var(--spread-value));

    /* padding-right: calc(var(--spread-value) * 2);
  margin-right: calc(-1 * var(--spread-value) * 2); */
  }
}

@screen md {
  .OfferCard {
    &__title {
      @apply text-primary-A900;
    }
  }
}

img {
  filter: brightness(45%);
}
@screen md {
  img {
    filter: drop-shadow(0px 10px 38px rgba(21, 44, 115, 0.25));
  }
}
@screen md {
  .swiper-slide-next {
    div {
      img {
        height: 380px;
      }
    }
  }
}
.active-bullet {
  & {
    @apply flex items-center;
  }
  &::after {
    @apply bg-primary-800 ml-4;
    content: '';
    display: inline-block;
    width: 20px;
    height: 2px;
  }
}
</style>
