import { useQuery } from 'villus';
import { GetJobVacanciesDocument, type JobVacanciesOutput } from '~/graphql/Vacancies';

export function useVacancies() {
  const { execute, isFetching } = useQuery({
    query: GetJobVacanciesDocument.toString(),
  });
  const vacancies = ref<JobVacanciesOutput[]>([]);
  onNuxtReady(async () => {
    const { data, error } = await execute();
    if (error) {
      console.error(error);
    }

    if (data) {
      console.log(data, 'data');
      vacancies.value = toNonNullable(data.getJobVacancies) || [];
    }
  });

  return { vacancies, isFetching };
}
