<template>
  <div class="">
    <MainTitle>
      <template #before>
        {{ $t('titleSegment1') }}
      </template>
      {{ $t('titleSegment2') }}
    </MainTitle>
    <ul class="[ grid grid-cols-2 gap-x-12 md:gap-x-22 gap-y-12 ] mt-12 lg:flex items-center justify-between">
      <li class="flex-1 flex flex-col text-center items-center">
        <svg-icon name="graph/health-insurance" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info1') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc1') }}</p>
      </li>
      <li class="flex-1 flex flex-col text-center items-center">
        <svg-icon name="graph/social-insurance" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info2') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc2') }}</p>
      </li>
      <li class="flex-1 flex flex-col text-center items-center">
        <svg-icon name="graph/work-from-home" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info3') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc3') }}</p>
      </li>
      <li class="flex-1 flex flex-col text-center items-center">
        <svg-icon name="graph/casual-dress-code" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info4') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc4') }}</p>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>
<i18n>
{
    "en": {
        "title": "Miraco",
        "info1": "Health Insurance",
        "desc1": "Enjoy health, dental and life insurance for all employees",
        "info2": "Social Insurance",
        "desc2": "We got you covered in the social insurance",
        "info3": "Work From Home",
        "desc3": "Work from home policy and great vacation time",
        "info4": "Casual Dress Code",
        "desc4": "Feel free to be yourself",
        "shopFrom": "Why Shop From",
        "titleSegment1": "Perks and",
        "titleSegment2": "Benefits"
    },
    "ar": {
        "title": "ميراكو",
        "info1": "تأمين صحى",
        "desc1": "تأمين صحي شامل بدون حد اقصى",
        "info2": "تامين اجتماعي",
        "desc2": "تامين اجتماعي مطابق لمعايير الحكومة المصرية",
        "info3": "العمل من المنزل",
        "desc3": "تأمين شامل ضد الحوادث وحالات الوفاه",
        "info4": "  الاجتماعية",
        "desc4": "مشاركة جميع العاملين 
بالمزايا العينية في جميع المناسبات",
        "shopFrom": "لماذا عليك الشراء من ",
        "titleSegment1":  "مزايا",
        "titleSegment2": "ميراكو"
    }
}
</i18n>
